import React, { useMemo } from "react";
import { keyBy as _keyBy, omitBy as _omitBy } from "lodash";
import { navigate } from "gatsby";
import { Row, Col, List } from "antd";
import useSearch from "hooks/useSearch";

// Already included by Gatsby
import { useLocation } from "@reach/router";
import queryString from "query-string";

import SearchBox from "./SearchBox";
import CategorySelect from "./CategorySelect";

import styles from "./SearchableList.module.less";

/**
 * @template T
 *
 * @typedef {object} SearchCategory
 * @prop {string} [name] Unused for now
 * @prop {(selectedCategory: string, node: T) => boolean} search
 * @prop {readonly string[]} values All categories available
 */

/**
 * @template T
 * @typedef {object} CustomProps
 * @prop {any} index The search index
 * @prop {SearchCategory<T>} categories
 * @prop {object} [searchConfig]
 */

/**
 * We allow for every list prop except children
 * @template T
 * @typedef {Omit<import("antd/es/list").ListProps<T>, "children">} ListProps
 */

/**
 * This is just an antd list with search on top
 * This makes handling the layout much simpler
 *
 * @template {{id: string}} T
 *
 * @param {CustomProps<T> & ListProps<T>} props
 */
export default function SearchableList(props) {
  const {
    index,
    categories,
    // 3 props from antd list that we tweak
    dataSource,
    pagination,
    renderItem,
    searchConfig,
    ...otherListProps
  } = props;

  const location = useLocation();

  const itemsById = useMemo(() => _keyBy(dataSource, "id"), [dataSource]);

  /** @type {Record<string,string>} */
  // @ts-ignore
  const search = location.search ? queryString.parse(location.search) : {};

  const setFields = (fields) => {
    const newSearch = _omitBy(
      { ...search, ...fields },
      (val) => !Boolean(val) || val === 1
    );
    let newPath = location.pathname;
    if (Object.keys(newSearch).length) {
      newPath += "?" + queryString.stringify(newSearch);
    }
    navigate(newPath, {
      replace: true,
    });
  };

  const searchText = search.q || "";
  const selectedCategory = search.category || null;
  const page = search.page || 1;

  const resultsIds = useSearch(searchText, index, searchConfig);

  let results = searchText ? resultsIds.map((id) => itemsById[id]) : dataSource;

  if (selectedCategory) {
    results = results.filter((node) =>
      categories.search(selectedCategory, node)
    );
  }

  return (
    <section className={styles.container}>
      <Row className={styles.header} gutter={24}>
        <Col xs={24} lg={12} className={styles.headerBox}>
          <CategorySelect
            categories={categories}
            value={selectedCategory}
            onChange={(newCategory) => {
              setFields({
                page: 1,
                category: newCategory,
              });
            }}
          />
        </Col>
        <Col xs={24} lg={12} className={styles.headerBox}>
          <SearchBox
            query={searchText}
            onSearch={(newQuery) => {
              setFields({
                page: 1,
                q: newQuery,
              });
            }}
          />
        </Col>
      </Row>
      <List
        dataSource={results.filter((item) => Boolean(item))}
        // Needed for search anyways
        rowKey="id"
        // Default pagination settings. Can be disabled/edited by the upper component
        pagination={{
          // @ts-ignore
          current: parseInt(page, 10),
          position: "bottom",
          defaultPageSize: 10,
          showSizeChanger: false,
          hideOnSinglePage: true,
          onChange: (page) => setFields({ page }),

          ...pagination,
        }}
        renderItem={(item, index) => (
          <List.Item>{renderItem(item, index)}</List.Item>
        )}
        locale={{
          emptyText: "No result",
        }}
        {...otherListProps}
      />
    </section>
  );
}
