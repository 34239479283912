import React from "react";
import { Input } from "antd";
import Icon from "@ant-design/icons";

import SearchIcon from "assets/icons/search.svg";
import styles from "./SearchableList.module.less";

/**
 * @typedef {object} CustomProps
 * @prop {string} query
 * @prop {(text: string) => void} onSearch
 * @param {CustomProps} props
 */
export default function SearchBox(props) {
  const { query, onSearch } = props;
  return (
    <Input
      role="search"
      className={styles.searchBox}
      suffix={<Icon className={styles.searchIcon} component={SearchIcon} />}
      value={query}
      onChange={(event) => {
        onSearch(event.target.value);
      }}
      placeholder="Search..."
      aria-label="Search through the items"
    />
  );
}
