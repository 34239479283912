import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import SearchableList from "components/SearchableList";
import BlogPostThumbnail from "components/BlogPostThumbnail";
import SEO, { createQueryImageFromFile } from "components/SEO";

import styles from "./styles.module.less";

const DATE_PUBLISHED = new Date("2019-08-12T12:51:31+00:00");

export const pageQuery = graphql`
  query BlogIndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        sharingImage {
          image {
            ...OpenGraphImage
            ...TwitterImage
            ...StructuredDataImage
          }
          alt
          caption
        }
      }
    }
    # Search index for the page
    localSearchPost {
      index
    }
    # Get all posts sorted by date
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      tags: distinct(field: frontmatter___tags)
      edges {
        node {
          ...BlogPostThumbnail
        }
      }
    }
  }
`;

/**
 * @typedef {object} Props
 * @prop {Location} location
 * @prop {GatsbyTypes.BlogIndexPageQuery} data
 * @param {Props} props
 */
export default function Blog(props) {
  const {
    location: { pathname },
    data: {
      markdownRemark: {
        frontmatter: { title: pageTitle, description, sharingImage },
      },
      allMarkdownRemark,
      localSearchPost,
    },
  } = props;
  const [
    openGraph,
    twitter,
    sdPrimaryImage,
  ] = createQueryImageFromFile(sharingImage, [
    "openGraphImage.resize",
    "twitterImage.resize",
    "sdImage.resize",
  ]);

  const latestUpdate = allMarkdownRemark.edges.reduce((acc, post) => {
    return new Date(
      Math.max(
        new Date(post.node.fields.dateModified).getTime(),
        new Date(acc).getTime()
      )
    );
  }, new Date(allMarkdownRemark.edges[0].node.fields.dateModified));

  return (
    <Layout gradientBackground>
      <SEO
        pathname={pathname}
        title={pageTitle}
        description={description}
        images={{ openGraph, twitter, sdPrimaryImage }}
        datePublished={DATE_PUBLISHED}
        dateModified={latestUpdate}
      />
      <div className={styles.regularContainer}>
        <SearchableList
          searchConfig={{
            fields: {
              title: { boost: 2, bool: "AND" },
              body: {},
              description: {},
              subtitle: {},
            },
          }}
          dataSource={allMarkdownRemark.edges.map(({ node }) => node)}
          categories={{
            name: "Tags",
            search: (selectedCategory, node) =>
              node.frontmatter.tags.includes(selectedCategory),
            values: allMarkdownRemark.tags,
          }}
          pagination={{
            defaultPageSize: 16,
          }}
          grid={{
            gutter: 32,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4,
            xxl: 4,
          }}
          renderItem={(post, index) => (
            <BlogPostThumbnail key={`postthumbnail-${index}`} {...post} />
          )}
          index={localSearchPost.index}
        />
      </div>
    </Layout>
  );
}
