import React from "react";
import { Dropdown, Button, Menu } from "antd";
import Icon, { CloseOutlined } from "@ant-design/icons";

import MoreIcon from "assets/icons/more.svg";

import styles from "./SearchableList.module.less";

/**
 * @template T
 * @typedef {import("./SearchableList").SearchCategory<T>} SearchCategory
 */

/**
 * @typedef {object} MenuProps
 * @prop {React.ComponentProps<typeof Menu>["onClick"]} onClick
 * @prop {SearchCategory<any>} categories
 * @prop {string} value
 * @param {MenuProps} props
 */
const CategoryMenu = ({ onClick, categories, value }) => {
  return (
    <Menu selectedKeys={value ? [value] : []} onClick={onClick}>
      {categories.values.map((categoryValue) => {
        return <Menu.Item key={categoryValue}>{categoryValue}</Menu.Item>;
      })}
    </Menu>
  );
};

const CategoryTitle = (props) => {
  const { title = "Browse by category", value, onChange } = props;
  const text = value || title;
  let icon;
  if (value) {
    icon = (
      <CloseOutlined
        onClick={(e) => {
          // If the close button is clicked, this shouldn't re-open
          e.stopPropagation();
          onChange(null);
        }}
      />
    );
  } else {
    icon = <Icon component={MoreIcon} />;
  }
  return (
    <div className={styles.categoryTitle}>
      <span className={styles.categoryTitleText}>{text}</span>
      {icon}
    </div>
  );
};

/**
 * @typedef {object} Props
 * @prop {string} [title]
 * @prop {SearchCategory<any>} categories
 * @prop {string} value
 * @prop {(newValue?) => void} onChange
 * @param {Props} props
 */
export default function CategorySelect(props) {
  const { title = "Browse by category", categories, value, onChange } = props;
  return (
    <Dropdown
      className={styles.categorySelect}
      overlay={
        <CategoryMenu
          value={value}
          onClick={(param) => {
            if (onChange) {
              // If clicking on something selected, we send null
              onChange(value === param.key ? null : param.key);
            }
          }}
          categories={categories}
        />
      }
      trigger={["click"]}
    >
      <Button>
        <CategoryTitle title={title} value={value} onChange={onChange} />
      </Button>
    </Dropdown>
  );
}
