import { useState, useEffect, useMemo } from "react";
import { Index } from "elasticlunr";

/**
 * @typedef {import("elasticlunr").Index} SearchIndex
 *
 * @param {string} query
 * @param {import("elasticlunr").SerialisedIndexData} providedIndex
 * @param {object} [searchConfig]
 *
 */
const useSearch = (query, providedIndex, searchConfig = {}) => {
  /** @type {[SearchIndex, React.Dispatch<SearchIndex>]} */
  const [index, setIndex] = useState(null);

  useEffect(() => {
    if (!providedIndex) {
      console.warn(
        "An index was not provided. Your search results will be empty."
      );
      setIndex(null);
      return;
    }

    const importedIndex = Index.load(providedIndex);
    setIndex(importedIndex);
  }, [providedIndex]);

  return useMemo(() => {
    if (!query || !index) return [];
    const results = index.search(query, {
      expand: true,
      bool: "OR",
      ...searchConfig,
    });
    return results.map((result) => result.ref);
  }, [query, index, searchConfig]);
};

export default useSearch;
